/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "better-hearing-can-make-your-heart-happy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#better-hearing-can-make-your-heart-happy",
    "aria-label": "better hearing can make your heart happy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Better Hearing Can Make Your Heart Happy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ah, Valentine’s Day. A day where people in the U.S are expected to spend more than $24 billion this year on temporary pleasures like red roses, candy hearts and delicious chocolates. While these are very lovely and delicious things that make hearts and bellies happy, there’s a more meaningful, permanent gift that may help improve and protect relationships for the long term: hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids probably aren’t the first things you think of when planning a romantic weekend or celebration of love. But let me tell you why it should be top of mind if you or someone you know has hearing loss."), "\n", React.createElement(LandingPageCta, {
    copy: "Learn about our hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "improved-communication",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#improved-communication",
    "aria-label": "improved communication permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Improved communication"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many important things that make up healthy and successful relationships, but they all start with good communication. Listening, understanding and responding back appropriately are all key when having a conversation with loved ones."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine talking with your family and not being able to understand what is being said. If you or someone you know it struggling to hear, you’ve probably experienced one or more of these common reactions:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "“Huh? Can you repeat that?” or “What did you say?”"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ignore the conversation and stop responding altogether."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Respond inappropriately with something that doesn’t make sense."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Get upset and walk away."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now, imagine if the scenarios above continued to happen day in and day out in your relationships for years. Whether you’re the one with hearing loss, or it’s happening to someone you know, you’re likely to experience a lot of frustration and draining arguments, ultimately leading to a less happy relationship overall."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What many don’t realize is that hearing loss is actually a problem with understanding words – not the volume of what is being said. This is because our brain actually processes and makes sense of sound. That’s right, we actually hear with our brain and not our ears. Because most people think their hearing challenge is simply a volume issue, they’ll continue to turn up the TV or ask people to “speak up,” thinking that will solve the problem. Unfortunately, it won’t, and untreated hearing loss will just continue to get worse."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But there is a solution. Modern hearing aids, like the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "Horizon IX by hear.com"), ", put most hearing challenges in the past. With a speech focus enhancer and incredible background noise reduction, you’ll be able to focus on what matters most in your life – communicating effectively with those you love."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/older_couple_dancing.png",
    alt: "couple laughing together",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "Reclaim your life. Wear the correct hearing aids.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "more-interest-in-activities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#more-interest-in-activities",
    "aria-label": "more interest in activities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "More interest in activities"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s proven that hearing loss can cause fatigue. Why? As mentioned above, our brain is what makes sense of sound. So, if you have hearing loss, your brain has to work overtime to try and understand what’s being said. When you’re concentrating extremely hard all day, every day, to understand what’s being said on the TV, what your friends and family are saying to you at home and over the phone, or even when you’re talking with the pharmacist or grocery store clerk, you’ll find yourself exhausted throughout the day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fatigue makes us all cranky and disinterested in doing much of anything. Lacking energy can wreak havoc on your social life and your willingness to participate in outings or hobbies you once enjoyed. In fact, The National Council on the Aging ", React.createElement("a", {
    href: "https://www.ncoa.org/",
    target: "_blank"
  }, "(NCOA)"), " surveyed 2,300 adults over 50 who have hearing loss and found that people with untreated hearing loss were less likely to participate in organized social activities, compared to those who wear hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Those with hearing loss may avoid things they love because their inability to hear may prevent them from fully being able to participate in activities and conversations and sometimes even lead to embarrassment. For example, if someone is playing tennis or even having a group dinner at a local restaurant and they can’t hear what’s being said and respond inappropriately, they may get teased or criticized for misunderstanding. This can really take a toll on someone’s mental health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But with today’s medical-grade hearing aids, the misunderstanding and embarrassment can be things of the past. The lightning-fast technology chip in today’s devices is 50 times faster than it was five years ago. This means that the newest hearing devices on the market, like the hear.com Horizon IX hearing aid, immensely improve speech clarity so your brain doesn’t have to put as much effort into understanding sound. This means you will have more energy and can get back to enjoying all the activities and hobbies you once loved, especially with your friends and family."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "increased-happiness",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#increased-happiness",
    "aria-label": "increased happiness permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Increased happiness"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If someone has gotten used to hearing loss overtime and hasn’t acted on treating it, they may not realize how much it’s impacting their happiness."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you can communicate better with loved ones, and participate in activities you love, you’ll automatically feel more energized and ultimately, happier. Untreated hearing loss can lead to loneliness, depression and isolation, so staying connected with good hearing is extremely important when it comes to feeling happy with yourself and those around you. The NCOA study mentioned above showed that not only did hearing aid wearers experience big improvements in their relationships, so did their families. And another ", React.createElement("a", {
    href: "https://www.ehima.com/surveys/",
    target: "_blank"
  }, "survey by EuroTrak"), ", the largest comparative multi-country study on hearing loss and hearing aid usage, found that more than half of all hearing aid wearers said their home relationships were better since they started wearing hearing aids, and 96% of people said they had an improved overall quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "gift-the-benefits-of-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gift-the-benefits-of-better-hearing",
    "aria-label": "gift the benefits of better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gift the benefits of better hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that you’ve learned some negative things that can come with untreated hearing loss, it’s time to experience the amazing benefits the best hearing aids can bring:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Being able to hear your friend or loved one laugh at a joke you told"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Understanding every word someone says and being able to hear the punchline of a joke – the first time!"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fully participating in conversations, hobbies and evenings out with your partner"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing a child ask you a question and being able to respond quickly and easily"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Having energy to do all the things you love"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Being 100% engaged with your loved ones not just on Valentine’s Day, but every day"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While you’re gifting gorgeous red roses this year, make sure to also have a conversation with your loved one today about the benefits of better hearing. And how you can improve and protect your relationship for years to come."), "\n", React.createElement(LandingPageCta, {
    copy: "Get the Horizon IX by hear.com",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
